// JavaScript Document
    
$(function(){
    
$(window).scroll(function() {
var scroll = $(window).scrollTop();
    

if (scroll >= 130) {
$('.navbar').addClass('fixed-top');
}
else{
$('.navbar').removeClass('fixed-top');
}
});
});

// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import $ from 'jquery/dist/jquery.min.js'
import {initBootstrap} from "./bootstrap.js"
import './menu-fixed.js'

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
//$(document).ready(() => console.log('Hello world!'))
